import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "lemajic" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Lemajic extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>
          A tall forward, strong in the air, able to act well on set pieces and to position himself optimally for a calculated header. When he's playing, the defense always has an option to start the attack with a long pass, as the percentage of aerial balls won by Lemajić is very high.
          </p>
          <a href="../../player-reports/lemajic-en.pdf" className="pdf-link" target="_blank">
            Player report
          </a>
          <p>
          Taking the ball in the penalty area, he's always ready for a shot. Not having the highest speed with the ball, he's very stable, rational, holds the ball and sees the field well, reacts to the actions of his partners.
          </p>
          <div className="video">
            <iframe
              title={ this.fullName() }
              src="https://www.youtube.com/embed/-GN4_RbU7N4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <p>
          Strong off-ball sprints, able to put pressure on the opposition defenders for long loose balls. He played for the Primavera team of Parma.
          </p>
          <p>
          In the season of 2018, he was the best scorer of the Latvian First League. Also he won the league title and the cup with Riga that season.
          </p>
          <img src="/images/players/lemajic/photo1.jpg" alt={ this.fullName() } />
        </div>
      </div>
    )
  }
}